import { generateAkamaiUrl } from '../../../helpers/Akamai'


export const blockFocusTransformer = (item, t, variant = '') => ({
  variant,
  title: `${t('textFocusLeft')} <span class="link">${t('textFocusRight')}</span>`,
  text: item?.fieldParagrapheContenuResume?.value ?? '',
  image: item?.fieldImageAncre?.fieldImageArticle ? {
    src: generateAkamaiUrl(item?.fieldImageAncre?.fieldImageArticle?.url ?? ''),
    alt: item?.fieldImageAncre?.alt ?? '',
  } : null,
  linkProps: item?.fieldLink?.url?.path ? {
    href: item?.fieldLink?.url?.path ?? undefined,
  } : undefined,
})

