import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

import withMemo from '../../decorators/withMemo'
import VideoPlayer from '../VideoPlayer'


const VideoPlayerWithPlaylist = (props) => {
  const { playlistProps, isInPush } = props
  const { t } = useTranslation()
  const router = useRouter()
  const [currentVideo, setCurrentVideo] = useState(0)
  const [currentPreviousVideo, setCurrentPreviousVideo] = useState(0)
  const [currentNextVideo, setCurrentNextVideo] = useState(1)


  const commonProps = useMemo(() => ({
    textPlay: t('customPlayerPlay'),
    textTranscription: t('customPlayerTranscription'),
    textOpen: t('customPlayerOpen'),
    textSubtitles: t('customPlayerSubtitles'),
    textSpeed: t('customPlayerSpeed'),
    speeds: [
      {
        text: '0.5',
        value: 0.5,
      },
      {
        text: t('customPlayerSpeedNormal'),
        isDefault: true,
        value: 1,
      },
      {
        text: '1.5',
        value: 1.5,
      },
    ],
  }), [t])

  useEffect(() => {
    setCurrentVideo(0)
    setCurrentPreviousVideo(playlistProps.length - 1)
    setCurrentNextVideo(1)
  }, [playlistProps.length, router.asPath])

  const handleNext = useCallback(() => {
    const newCurrentVideoIndex = currentVideo + 1 >= playlistProps.length ? 0 : currentVideo + 1
    const newNextVideoIndex = newCurrentVideoIndex + 1 >= playlistProps.length ? 0 : newCurrentVideoIndex + 1
    const newPrevVideoIndex = currentVideo

    setCurrentVideo(newCurrentVideoIndex)
    setCurrentNextVideo(newNextVideoIndex)
    setCurrentPreviousVideo(newPrevVideoIndex)
  }, [currentVideo, playlistProps])

  const handlePrev = useCallback(() => {
    const newCurrentVideoIndex = currentVideo - 1 < 0 ? (playlistProps.length - 1) : currentVideo - 1
    const newNextVideoIndex = currentVideo
    const newPrevVideoIndex = newCurrentVideoIndex - 1 < 0 ? (playlistProps.length - 1) : newCurrentVideoIndex - 1

    setCurrentVideo(newCurrentVideoIndex)
    setCurrentNextVideo(newNextVideoIndex)
    setCurrentPreviousVideo(newPrevVideoIndex)
  }, [currentVideo, playlistProps.length])

  const videoProps = useMemo(() => ({
    ...commonProps,
    ...playlistProps[currentVideo],
    nextVideoHandler: handleNext,
    prevVideoHandler: handlePrev,
    nextSource: playlistProps.length > 1 ? {
      title: playlistProps[currentNextVideo]?.title ?? null,
      poster: playlistProps[currentNextVideo]?.videoSource?.poster ?? null,
      src: playlistProps[currentNextVideo]?.videoSource?.sources[0]?.src ?? null,
      type: playlistProps[currentNextVideo]?.videoSource?.sources[0]?.type ?? null,
    } : null,
    prevSource: playlistProps.length > 1 ? {
      title: playlistProps[currentPreviousVideo]?.title ?? null,
      poster: playlistProps[currentPreviousVideo]?.videoSource?.poster ?? null,
      src: playlistProps[currentPreviousVideo]?.videoSource?.sources[0]?.src ?? null,
      type: playlistProps[currentPreviousVideo]?.videoSource?.sources[0]?.type ?? null,
    } : null,
  }), [commonProps, currentNextVideo, currentPreviousVideo, currentVideo, handleNext, handlePrev, playlistProps])

  return (
    <>
      <VideoPlayer
        {...videoProps}
        isInPush={isInPush}
      />
    </>
  )
}

VideoPlayerWithPlaylist.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  playlistProps: PropTypes.array,
  isInPush: PropTypes.bool,
}

VideoPlayerWithPlaylist.defaultProps = {
  playlistProps: [],
  isInPush: false,
}

export default withMemo()(VideoPlayerWithPlaylist)
