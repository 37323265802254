import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'
import A from '../A'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const SearchResult = (props) => {
  const {
    classes: classesProp,
    className,
    topic,
    section,
    image,
    title,
    linkProps,
    summary,
    textButton,
    TagTitle,
    variant,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const renderTopicText = useMemo(() => (variant === 'inpractice' ? topic?.text.split(' ').map((word, index) => (
    <React.Fragment key={`searchresult-topictext-${index}`}>
      <span
        className={classes.topicText}
      >
        {word}
      </span>
      {' '}
    </React.Fragment>
  )) : (<span dangerouslySetInnerHTML={{ __html: topic?.text }} />)), [classes.topicText, topic, variant])

  return (
    <article className={cx(classes.container, className, `is-${variant}`)}>
      {(topic || section) && (
        <div className={classes.category}>
          {topic && (
          <h2 className={classes.topic}>
            <A {...topic.linkProps}>{renderTopicText}</A>
          </h2>
          )}
          {section && (
          <h2 className={classes.section}>
            <A
              {...section.linkProps}
            >
              <span dangerouslySetInnerHTML={{ __html: section.text }} />
            </A>
          </h2>
          )}
        </div>
      )}
      <div className={classes.main}>
        {image && (
          <figure className={classes.figure}>
            <Image
              className={classes.image}
              {...image}
            />
          </figure>
        )}
        <div className={classes.content}>
          {title && (
            <TagTitle className={classes.title}>
              <A
                {...linkProps}
              >
                {title}
              </A>
            </TagTitle>
          )}
          {summary && (
            <MarkdownText
              className={classes.summary}
              text={summary}
              lexicon={lexicon}
              markHover={markHover}
            />
          )}
          <div
            className={classes.buttons}
          >
            <A
              className={classes.button}
              {...linkProps}
            >
              {textButton}
            </A>
          </div>
        </div>
      </div>
    </article>
  )
}

SearchResult.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  topic: PropTypes.shape(
    {
      text: PropTypes.string,
      linkProps: PropTypes.shape(A.propTypes),
    }
  ),
  section: PropTypes.shape(
    {
      text: PropTypes.string,
      linkProps: PropTypes.shape(A.propTypes),
    }
  ),
  image: PropTypes.shape(Image.propTypes),
  title: PropTypes.string,
  linkProps: PropTypes.shape(A.propTypes).isRequired,
  summary: PropTypes.string,
  textButton: PropTypes.string.isRequired,
  TagTitle: PropTypes.string,
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'inpractice', 'default']).isRequired,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

SearchResult.defaultProps = {
  className: null,
  classes: null,
  topic: null,
  section: null,
  image: null,
  title: '',
  summary: '',
  TagTitle: 'h3',
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(SearchResult)
