import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useKeenSlider } from 'keen-slider/react'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'
import A from '../A'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavRooms = (props) => {
  const {
    classes: classesProp,
    className,
    practices,
    textPrevious,
    textNext,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])


  // states

  // slider settings
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 2,
    loop: true,
    mounted() {
      if (sliderRef.current) {
        sliderRef.current.style.visibility = 'visible'
      }
    },
    slideChanged(s) {
    },
    breakpoints: {
      '(min-width: 340px)': {
        slidesPerView: 3,
        slideChanged(s) {
        },
      },
      '(min-width: 750px)': {
        slidesPerView: 5,
        slideChanged(s) {
        },
      },
      '(min-width: 980px)': {
        loop: false,
        slidesPerView: 10,
      },
    },
  })

  // handlers
  const handlePrev = useCallback(() => {
    if (slider) {
      slider.prev()
    }
  }, [slider])

  const handleNext = useCallback(() => {
    if (slider) {
      slider.next()
    }
  }, [slider])

  const handleResize = useCallback(() => {
    if (slider) {
      slider.resize()
    }
  }, [slider])


  const handleCenterCurrent = useCallback(() => {
    const resolution = window?.innerWidth ?? 0
    const toCenterItem = resolution < 340
      ? 0
      : resolution < 750
        ? 1
        : 2

    if (slider) {
      slider.moveToSlide(
        (
          practices
          .map((practice) => practice.isActive ?? false)
          .indexOf(true) - toCenterItem
        )
        ?? 0
      )
    }
  }, [practices, slider])

  // effects
  // - move to current entry
  useEffect(() => {
    if (window) {
      const timer = setTimeout(() => {
        handleCenterCurrent()
      }, 300)

      return () => clearTimeout(timer)
    }
    handleCenterCurrent()
  }, [handleCenterCurrent])
  // - slider resize on change fonts size
  // - slider resize on window resize
  useEffect(() => {
    const o = ((typeof window !== 'undefined')) ? new MutationObserver(() => {
      handleResize()
    }) : null

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      if (o !== null) {
        o.observe(document.querySelector('html'), { attributes: true, attributeFilter: ['style'] })
      }
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleResize)
          if (o !== null) {
            o.disconnect()
          }
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleResize, slider])

  const renderLinks = useMemo(() => practices && (
    <>
      <div
        ref={sliderRef}
        className={cx(classes.keenSlider, 'keen-slider')}
      >
        {practices.map((link, index) => (
          <li
            className={cx(classes.slide, 'keen-slider__slide')}
            key={`navrooms-link-${index}`}
          >
            <A
              {...link.linkProps}
              className={cx(classes.link, link.isActive === true && 'is-current')}
            >
              <figure className={classes.figure}>
                <Image
                  {...link.image}
                  className={classes.image}
                />
              </figure>
              <span className={classes.text}>{link.text}</span>
            </A>
          </li>
        ))}
      </div>
      <button
        type="button"
        className={cx(classes.previous, classes.nav)}
        onClick={handlePrev}
      >
        <Icon
          icon={icons.ChevronSquareLeft}
          className={classes.navIcon}
        />
        <i>{textPrevious}</i>
      </button>
      <button
        type="button"
        className={cx(classes.next, classes.nav)}
        onClick={handleNext}
      >
        <Icon
          icon={icons.ChevronSquareRight}
          className={classes.navIcon}
        />
        <i>{textNext}</i>
      </button>
    </>
  ),
  // eslint-disable-next-line max-len
  [classes.figure, classes.image, classes.keenSlider, classes.link, classes.nav, classes.navIcon, classes.next, classes.previous, classes.slide, classes.text, handleNext, handlePrev, practices, sliderRef, textNext, textPrevious])

  return practices && (
    <nav className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {renderLinks}
      </div>
    </nav>
  )
}

NavRooms.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  practices: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      image: PropTypes.shape(Image.propTypes),
      linkProps: PropTypes.shape(A.propTypes),
      isActive: PropTypes.bool,
    })
  ),
  textPrevious: PropTypes.string.isRequired,
  textNext: PropTypes.string.isRequired,
}

NavRooms.defaultProps = {
  className: null,
  classes: null,
  practices: null,
}

export default withMemo()(NavRooms)
