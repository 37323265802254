import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import slugify from 'slugify'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'
import { selectors as PageSelectors } from '../../Wrappers/Pages/Page/redux'
import {
  actions as ToolActions
} from '../../Wrappers/Templates/OutilWrapper/redux'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PracticeMap = (props) => {
  const {
    classes: classesProp,
    className,
    backgroundImage,
    mapSlides,
    currentMap,
    referenceWidth,
  } = props
  const dispatch = useDispatch()
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])
  const { query } = useRouter()
  const slug = useSelector(PageSelectors.slug)

  const setCurrentZone = useCallback((title) => {
    window.history.pushState({}, '', `/${query.locale}/${slug}/${title}`)

    dispatch(ToolActions.setCurrent(title))
  }, [dispatch, query.locale, slug])

  //
  const renderMapDots = useMemo(
      () => mapSlides.map((slide, index) => {
        const positionX = (slide.positionX / referenceWidth) * 100
        const positionY = (slide.positionY / referenceWidth) * 100

        const titleFormatted = slugify(slide.title.length ? slide.title : (slide.image?.alt.length ? slide.image?.alt : slide.text), {
          lower: true,
          strict: true,
        })

        return (
          <button
            type="button"
            onClick={() => setCurrentZone(titleFormatted)}
            key={`practicemap-dot-${index}`}
            style={{
                  top: `${positionY}%`,
                  left: `${positionX}%`,
                }}
            className={classes.dot}
          >
            <i>{slide.text ?? ' '}</i>
          </button>
        )
      }),
      [classes.dot, mapSlides, referenceWidth, setCurrentZone]
  )
  //
  const renderMapImage = useMemo(() => typeof currentMap === 'number' && currentMap >= 0 && (
    <Image
      className={classes.image}
      {...mapSlides[currentMap].image}
    />
  ), [classes.image, currentMap, mapSlides])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.dots}>{renderMapDots}</div>
        <div className={classes.background}>
          {renderMapImage}
          <Image
            className={classes.backgroundImage}
            {...backgroundImage}
          />
        </div>
      </div>
    </div>
  )
}

PracticeMap.propTypes = {
  className: PropTypes.string,
  currentMap: PropTypes.number,
  classes: PropTypes.objectOf(PropTypes.string),
  backgroundImage: PropTypes.shape(Image.propTypes),
  mapSlides: PropTypes.arrayOf(
      PropTypes.shape({
        groupId: PropTypes.string,
        positionX: PropTypes.number,
        positionY: PropTypes.number,
        image: PropTypes.shape(Image.propTypes),
        text: PropTypes.string,
      })
  ),
  image: PropTypes.shape(Image.propTypes),
  onMarkerClick: PropTypes.func,
  referenceWidth: PropTypes.number,
}

PracticeMap.defaultProps = {
  className: null,
  currentMap: -1,
  classes: null,
  backgroundImage: null,
  mapSlides: null,
  image: null,
  onMarkerClick: () => null,
  referenceWidth: 600,
}

export default withMemo()(PracticeMap)
