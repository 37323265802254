import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useKeenSlider } from 'keen-slider/react'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'
import Icon from '../Icon'
import icons from '../Icon/assets'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PracticeSlider = (props) => {
  const {
    classes: classesProp,
    className,
    id,
    slides,
    textPrevious,
    textNext,
    visibleSlides,
    onProductChange,
    fromOriginalIndex,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])
  const arrPaddedSlides = useMemo(() => {
    // to guarantee that `loop: true` won't break, we pad the slide with "fake slides" if there's not a least visibleSlides + 1
    const numberPaddingSlides = Math.ceil((visibleSlides + 1) / slides.length)

    const tmpArrPaddedSlides = []

    for (let i = 0; i < numberPaddingSlides; i++) {
      for (let x = 0; x < slides.length; x++) {
        tmpArrPaddedSlides.push({
          originalIndex: x,
          ...slides[x],
        })
      }
    }
    // offset the first bye one because the first item is the middle one
    tmpArrPaddedSlides.unshift(tmpArrPaddedSlides[tmpArrPaddedSlides.length - 1])
    tmpArrPaddedSlides.pop()

    return tmpArrPaddedSlides
  }, [slides, visibleSlides])

  // currentproduct is second of reworked array
  const [currentProduct, setCurrentProduct] = useState(1)

  // on slide change return the id and index of the original slides
  const handleChangeProduct = useCallback((index, productId) => {
    safeCall(onProductChange, index, productId)
  }, [onProductChange])

  // slider settings
  const [sliderRef, slider] = useKeenSlider({})

  const optionsSlider = useMemo(() => ({
    slidesPerView: 3,
    loop: true,
    mounted() {
      if (sliderRef.current) {
        sliderRef.current.style.visibility = 'visible'
      }
    },
    slideChanged(s) {
      if (arrPaddedSlides[s.details().relativeSlide + 1]) {
        setCurrentProduct(s.details().relativeSlide + 1)
        handleChangeProduct(arrPaddedSlides[s.details().relativeSlide + 1].originalIndex, arrPaddedSlides[s.details().relativeSlide + 1].productId)
      } else {
        setCurrentProduct(0)
        handleChangeProduct(arrPaddedSlides[0].originalIndex, arrPaddedSlides[0].productId)
      }
    },
  }), [arrPaddedSlides, handleChangeProduct, sliderRef])


  useEffect(() => {
    if (slider) {
      slider.refresh(optionsSlider)
      // setCurrentProduct(1)
      // slider.moveToSlide(0)
    }
  }, [optionsSlider, slider])

  useEffect(() => {
    if (fromOriginalIndex && slider) {
      const index = arrPaddedSlides.findIndex((pad) => pad.originalIndex === fromOriginalIndex)

      slider.refresh(optionsSlider)
      setCurrentProduct(index)
      slider.moveToSlide(fromOriginalIndex)
    }
  }, [arrPaddedSlides, fromOriginalIndex, optionsSlider, slider])

  // slider handlers
  const handlePrev = useCallback(() => {
    if (slider) {
      slider.prev()
    }
  }, [slider])

  const handleNext = useCallback(() => {
    if (slider) {
      slider.next()
    }
  }, [slider])

  const handleResize = useCallback(() => {
    if (slider) {
      slider.resize()
    }
  }, [slider])

  // effects
  // - slider resize on change fonts size
  // - slider resize on window resize
  useEffect(() => {
    const o = ((typeof window !== 'undefined')) ? new MutationObserver(() => {
      handleResize()
    }) : null

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      if (o !== null) {
        o.observe(document.querySelector('html'), { attributes: true, attributeFilter: ['style'] })
      }
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleResize)
          if (o !== null) {
            o.disconnect()
          }
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleResize, slider])

  // renderers
  const renderSlider = useMemo(() => (
    <div
      ref={sliderRef}
      className={cx(classes.slider, 'keen-slider')}
      role="navigation"
      id="practiceSlider"
    >
      {arrPaddedSlides.map((slide, index) => (
        <div
          key={`navpractice-column-${index}`}
          className={cx(classes.slide, 'keen-slider__slide', currentProduct === index && 'is-current')}
          data-index={slide.originalIndex}
          data-showproduct={slide.productId}
        >
          <figure className={classes.figure}>
            <Image
              className={classes.image}
              {...slide.image}
            />
          </figure>
        </div>
      ))}
    </div>

  ),
  // eslint-disable-next-line max-len
  [arrPaddedSlides, classes.figure, classes.image, classes.slide, classes.slider, currentProduct, sliderRef])

  return (
    <div
      className={cx(classes.container, className)}
      id={id}
    >
      {renderSlider}
      <h2 className={classes.text}>{arrPaddedSlides[currentProduct]?.text}</h2>
      {slider && (
        <>
          <button
            type="button"
            className={cx(classes.previous, classes.nav)}
            onClick={handlePrev}
          >
            <Icon
              icon={icons.ChevronSquareLeft}
              className={classes.navIcon}
            />
            <i>{textPrevious}</i>
          </button>
          <button
            type="button"
            className={cx(classes.next, classes.nav)}
            onClick={handleNext}
          >
            <Icon
              icon={icons.ChevronSquareRight}
              className={classes.navIcon}
            />
            <i>{textNext}</i>
          </button>
        </>
          )}
    </div>
  )
}

PracticeSlider.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  id: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape(Image.propTypes),
    })
  ),
  textPrevious: PropTypes.string.isRequired,
  textNext: PropTypes.string.isRequired,
  visibleSlides: PropTypes.number,
  fromOriginalIndex: PropTypes.number,
  onProductChange: PropTypes.func,
}

PracticeSlider.defaultProps = {
  className: null,
  classes: null,
  id: null,
  slides: null,
  fromOriginalIndex: null,
  visibleSlides: 3,
  onProductChange: () => null,
}

export default withMemo()(PracticeSlider)
