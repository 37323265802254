import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import slugify from 'slugify'

import withMemo from '../../../decorators/withMemo'
import HeaderPractice from '../../../components/HeaderPractice'
import BlockPractice from '../../../components/BlockPractice'
import PracticeMap from '../../../components/PracticeMap'
import PracticeSlider from '../../../components/PracticeSlider'
import PracticeTabs from '../../../components/PracticeTabs'
import NavRooms from '../../../components/NavRooms'
import { selectors as PageSelectors } from '../../Pages/Page/redux'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'
import { selectors as AppSelectors } from '../../../redux/app/redux'
import { interpretHtml } from '../../../helpers/StringHelpers'

import { actions as ToolActions, selectors as ToolSelectors } from './redux'
import {
  headerPracticeDetailTransformer,
  navigationPracticeTransformer,
  practiceMapTransformer,
  practiceSliderTransformer
} from './transformers'


function OutilWrapper(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const slug = useSelector(PageSelectors.slug)
  const tools = useSelector(ToolSelectors.tools)
  const route = useSelector(AppSelectors.route)
  const currentZoneFromSlug = useSelector(ToolSelectors.current)
  const searchSelectedResult = route.query?.field_desc ?? ''

  const [currentTab, setCurrentTab] = useState(0)
  const [currentContentSlide, setCurrentContentSlide] = useState(-1)
  const [currentProductSlider, setCurrentProductSlider] = useState(0)
  const [currentProduct, setCurrentProduct] = useState(0)
  const [isSetFromSearchOnce, setIsSetFromSearchOnce] = useState(false)
  const [hasFinishedSearching, setHasFinishedSearching] = useState(false)
  const [lexicals, handleLexicon] = useLexiconHandler()
  const headerPracticeDetailProps = useMemo(
      () => headerPracticeDetailTransformer({ url: route?.asPath, ...props }, t),
      [route.asPath, props, t]
  )
  const practiceMapProps = useMemo(() => practiceMapTransformer(props), [props])

  const currentZone = useMemo(() => practiceMapProps.mapSlides.findIndex(
      (object) => {
        const zoneSlug = slugify(object.title !== '' ? object.title : object.image.alt, {
          lower: true,
          strict: true,
        })

        return zoneSlug.toLowerCase() === currentZoneFromSlug?.toLowerCase()
      }
  ), [currentZoneFromSlug, practiceMapProps.mapSlides])

  const practiceSliderProps = useMemo(
      () => (currentZone >= 0 && props?.fieldOutilZones && props?.fieldOutilZones[currentZone]
          ? practiceSliderTransformer(props?.fieldOutilZones[currentZone], t)
          : null),
      [currentZone, props, t]
  )

  const setCurrent = useCallback((value) => {
    dispatch(ToolActions.setCurrent(value))
  }, [dispatch])

  const setCurrentZone = useCallback((zone) => {
    setCurrent(slugify(zone.title.length ? zone.title : (zone.image?.alt.length ? zone.image?.alt : zone.text), {
      lower: true,
      strict: true,
    }))
  }, [setCurrent])

  const setCurrentElementBasedOnSearchSummary = useCallback(() => {
    for (let index = 0; index < props?.fieldOutilZones?.length; index++) {
      const item = props?.fieldOutilZones[index]

      for (let i = 0; i < item.entity?.fieldZoneOutilProduits?.length; i++) {
        const el = item.entity?.fieldZoneOutilProduits[i]

        for (let id = 0; id < el.entity?.fieldProduitOnglets.length; id++) {
          const e = el.entity?.fieldProduitOnglets[id]

          for (let iid = 0; iid < e.entity?.fieldOngletsContenu.length; iid++) {
            const ee = e.entity?.fieldOngletsContenu[iid]

            const interpretedValue = interpretHtml(ee?.value)

            if (interpretedValue.indexOf(searchSelectedResult) >= 0) {
              setCurrentZone(practiceMapProps.mapSlides[index])
              setCurrentProductSlider(i)
              setCurrentProduct(i)
              setCurrentContentSlide(iid)
              setCurrentTab(id)
            }
          }
        }
      }
    }
  }, [props?.fieldOutilZones, searchSelectedResult, setCurrentZone, practiceMapProps.mapSlides])

  const setCurrentElementBasedOnValue = useCallback((value) => {
    let hasSelectedProduct = false

    for (let index = 0; index < props?.fieldOutilZones?.length; index++) {
      const item = props?.fieldOutilZones[index]

      if (item.entity?.fieldImageAncre?.alt?.toLowerCase().indexOf(value) >= 0) {
        setCurrentZone(practiceMapProps.mapSlides[index])
      }

      for (let i = 0; i < item.entity?.fieldZoneOutilProduits?.length; i++) {
        const el = item.entity?.fieldZoneOutilProduits[i]

        // eslint-disable-next-line max-len
        if (slugify(el.entity?.fieldProduitTitre?.toLowerCase()).indexOf(value) >= 0 && (!hasSelectedProduct || slugify(el.entity?.fieldProduitTitre?.toLowerCase()) === value)) {
          setCurrentZone(practiceMapProps.mapSlides[index])
          setCurrentProductSlider(i)
          setCurrentProduct(i)
          hasSelectedProduct = true
        }

        for (let id = 0; id < el.entity?.fieldProduitOnglets.length; id++) {
          const e = el.entity?.fieldProduitOnglets[id]

          for (let iid = 0; iid < e.entity?.fieldOngletsContenu.length; iid++) {
            const ee = e.entity?.fieldOngletsContenu[iid]

            if (ee.value?.toLowerCase().indexOf(value) >= 0) {
              if (!hasSelectedProduct) {
                setCurrentZone(practiceMapProps.mapSlides[index])
                setCurrentProductSlider(i)
                setCurrentProduct(i)
              }
              setCurrentTab(id)
              setCurrentContentSlide(iid)
            }
          }
        }
      }
    }
  }, [practiceMapProps.mapSlides, props.fieldOutilZones, setCurrentZone])

  const setCurrentElement = useCallback((value) => {
    if (value && searchSelectedResult) {
      setCurrentElementBasedOnSearchSummary()
    } else {
      setCurrentElementBasedOnValue(value)
    }

    setHasFinishedSearching(true)
  }, [searchSelectedResult, setCurrentElementBasedOnSearchSummary, setCurrentElementBasedOnValue])

  const setCurrentFromQuerySlug = useCallback(() => {
    if (route?.query?.slug && route?.query?.slug?.split('/').length > 1 && !route?.query?.search_fulltext) {
      const current = route?.query?.slug?.substr(route?.query?.slug?.indexOf('/') + 1)

      const slugifiedCurrent = slugify(current, {
        lower: true,
        strict: true,
      })

      if (current) {
        setCurrent(slugifiedCurrent)
        setCurrentElement(slugifiedCurrent)
      }
    }
  }, [route.query.search_fulltext, route.query.slug, setCurrent, setCurrentElement])

  const setCurrentElementFromSearch = useCallback(() => {
    if (route?.query?.search_fulltext) {
      const search = route?.query?.search_fulltext?.toLowerCase() ?? ''

      setCurrentElement(search)
    }
  }, [route.query.search_fulltext, setCurrentElement])

  const navRoomsProps = useMemo(() => {
    const propsNavgation = navigationPracticeTransformer(t, tools)

    propsNavgation.practices = propsNavgation.practices.map((practice) => ({
      ...practice,
      linkProps: {
        ...practice.linkProps,
        onClick: () => {
          setCurrent(null)
          setCurrentProduct(0)
          setCurrentContentSlide(0)
        },
      },
      isActive: practice?.linkProps?.href.indexOf(slug) >= 0 ?? false,
    }))

    return propsNavgation
  }, [setCurrent, slug, t, tools])

  const practiceTabsProps = useMemo(
    () => (practiceSliderProps
        ? currentProduct < practiceSliderProps.slides.length
          ? practiceSliderProps.slides[currentProduct]
          : practiceSliderProps.slides[0]
        : null),
    [currentProduct, practiceSliderProps]
  )

  const handleClickTab = useCallback((index) => {
    setCurrentTab(index)
    setCurrentContentSlide(0)
  }, [])

  const handleProductChange = useCallback((index) => {
    setCurrentProduct(index)
    if (practiceSliderProps && practiceSliderProps.slides[currentProductSlider] && practiceSliderProps.slides[currentProductSlider].tabs.length <= 1) {
      setCurrentTab(0)
    }
  }, [currentProductSlider, practiceSliderProps])

  const handleMarkerClick = useCallback((index) => {
    setCurrentZone(practiceMapProps[index])
    setCurrentProduct(0)
    setCurrentContentSlide(0)
  }, [practiceMapProps, setCurrentZone])

  const callbackClickContent = useCallback((index) => {
    setCurrentContentSlide(index)
  }, [])

  useEffect(() => {
    if (currentZoneFromSlug && document) {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        document.getElementById('practiceSlider')?.scrollIntoView()
      }, 900)
    }
  }, [currentZoneFromSlug])

  useEffect(() => {
    if (!route?.query?.search_fulltext && route?.query?.slug?.split('/').length > 1) {
      setHasFinishedSearching(true)
      setIsSetFromSearchOnce(true)
    }
  }, [route.query.search_fulltext, route.query.slug])

  useEffect(() => {
    if (!isSetFromSearchOnce) {
      setCurrentFromQuerySlug()
      setCurrentElementFromSearch()
    } else {
      setHasFinishedSearching(true)
    }
    setIsSetFromSearchOnce(true)
  }, [isSetFromSearchOnce, route.query.slug, setCurrentElementFromSearch, setCurrentFromQuerySlug])

  const renderSelectedSlider = useMemo(() => {
    if (currentZone === -1 || !practiceSliderProps || !hasFinishedSearching) {
      return null
    }

    return (
      <>
        <PracticeSlider
          {...practiceSliderProps}
          fromOriginalIndex={currentProductSlider}
          onProductChange={handleProductChange}
        />
        <PracticeTabs
          {...practiceTabsProps}
          key={`PracticeTabs-${currentProduct}`}
          lexicon={lexicals}
          currentTab={currentTab}
          currentContent={currentContentSlide}
          callbackClickContent={callbackClickContent}
          handleClickTab={handleClickTab}
          markHover={handleLexicon}
        />
      </>
    )
  // eslint-disable-next-line max-len
  }, [callbackClickContent, currentContentSlide, currentProduct, currentProductSlider, currentTab, currentZone, handleClickTab, handleLexicon, handleProductChange, hasFinishedSearching, lexicals, practiceSliderProps, practiceTabsProps])

  return (
    <>
      <HeaderPractice {...headerPracticeDetailProps} />
      <BlockPractice
        id={currentZone}
        textNoContent={`
          <p>
            <span class='espaces-intro-click'>${t('textNoContent')}</span> 
            <i class='point'></i>
            <span class='espaces-intro-text' style='margin-left: 30px;'> ${t(
                'textNoContent2'
            )}</span>
          </p>
        `}
        map={(
          <PracticeMap
            {...practiceMapProps}
            currentMap={currentZone}
            onMarkerClick={handleMarkerClick}
          />
            )}
        content={renderSelectedSlider}
      />
      <NavRooms {...navRoomsProps} />
    </>
  )
}

OutilWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldOutilZones: PropTypes.array,
}

OutilWrapper.defaultProps = {
  fieldOutilZones: [],
}

export default withMemo()(OutilWrapper)
