import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownText from '../MarkdownText'
import A from '../A'
import Image from '../Image'
import QuestionPushCard from '../QuestionPushCard'

import styles from './styles'


const useStyles = createUseStyles(styles)

const QuestionPushArticle = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    text,
    image,
    TagTitle,
    linkProps,
    // eslint-disable-next-line no-unused-vars
    variant,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <QuestionPushCard
      linkProps={linkProps}
      variant={variant}
      hasIcon
      className={cx(classes.container, className)}
    >
      {image && image.src && (
        <figure className={classes.figure}>
          <Image
            {...image}
            covered
            className={classes.image}
          />
        </figure>
      )}
      <div className={classes.content}>
        <MarkdownText
          tag={TagTitle}
          className={classes.title}
          text={title}
          lexicon={lexicon}
          markHover={markHover}
        />
        <MarkdownText
          className={classes.text}
          text={text}
          lexicon={lexicon}
          markHover={markHover}
        />
      </div>
    </QuestionPushCard>
  )
}

QuestionPushArticle.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.shape(Image.propTypes),
  TagTitle: PropTypes.string,
  linkProps: PropTypes.shape(A.propTypes),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

QuestionPushArticle.defaultProps = {
  className: null,
  classes: null,
  title: '',
  text: '',
  image: null,
  TagTitle: 'h3',
  linkProps: null,
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(QuestionPushArticle)
