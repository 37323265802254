import { generateAkamaiUrl } from '../../../helpers/Akamai'


export const pageLandingCtasTransformer = (props) => ({
    title: props?.fieldTitleLong ?? '',
    text: props?.fieldBody?.processed ?? '',
    image: {
        src: generateAkamaiUrl(props?.fieldImage?.entity?.url ?? ''),
    },
    buttons: props?.fieldLinks?.map((fieldLink) => ({
        text: fieldLink?.title ?? '',
        linkProps: {
            href: fieldLink?.url?.path ?? '#',
            target: '_blank',
        },
    })) ?? [],
})
