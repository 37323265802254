import { colors, desktop, grids, mobile, tablet, fonts, biggestDesktop } from '../../theme'


export default {
  container: {
    flex: '0 0 auto',
    width: '100%',
    maxWidth: `${grids.desktop.boxingMax / 10}rem`, // another exception
    position: 'relative',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#000',
    background: colors.pureBlack,
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    maxWidth: '143rem', // exception
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    height: '48rem',
  },
  figure: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1,
    margin: 0,
    '& > img': {
      transform: 'translate(-3rem, -15rem)',
      objectFit: 'cover',
      width: '120%',
      height: '95%',
      maxWidth: '120%',
      minWidth: '120%',
    },
  },
  main: {
    position: 'relative',
    zIndex: 5,
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  title: {
    position: 'relative',
    maxWidth: '31rem',
    margin: 0,
    lineHeight: 1.3,
    fontSize: '2.4rem',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  link: {
    ...fonts.MaaxBold,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '21rem',
    height: '4rem',
    padding: '1rem',
    border: '1px solid #000',
    borderRadius: '1.3rem',
    fontSize: '1.4rem',
    color: '#000',
    textTransform: 'uppercase',
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  text: {
    position: 'relative',
    fontSize: '1.5rem',
    lineHeight: 1.2,
    fontWeight: 700,
    maxWidth: '34rem',
    '&:not(:first-child)': {
      marginTop: '1.3rem',
      marginBottom: '0.5rem',
    },
  },
  toolsZone: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '3rem',
    color: '#000',
    '&:not(:first-child)': {
      marginTop: '1.5rem',
      marginBottom: '0',
    },
  },
  bubbleLeft: {
    position: 'absolute',
    zIndex: 3,
    top: '11rem',
    left: '-4rem',
    width: '33rem',
    height: '33rem',
    pointerEvents: 'none',
  },
  bubbleRight: {
    position: 'absolute',
    width: '13rem',
    height: '31rem',
    top: '-5.5rem',
    right: '0rem',
    zIndex: 4,
    pointerEvents: 'none',
  },
  gradient: {
    position: 'absolute',
    zIndex: 4,
    bottom: 0,
    left: 0,
    right: 0,
    height: '29rem',
    mixBlendMode: 'normal',
    opacity: '1',
    background: 'linear-gradient(360deg, #FFFFFF 62.72%, rgba(217, 217, 217, 0) 100%)',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '18rem',
    background: '#FFF',
    zIndex: 4,
  },
  ...mobile({
    wrapper: {
      height: '55.5rem',
    },
    title: {
      maxWidth: 'none',
      fontSize: '2.4rem',
      fontWeight: 900,
    },
    text: {
      '&:not(:first-child)': {
        marginTop: '3rem',
      },
    },
    link: {
      minWidth: '23rem',
      '&:not(:first-child)': {
        marginTop: '3rem',
      },
    },
    toolsZone: {
      '&:not(:first-child)': {
        marginTop: '8.8rem',
        marginBottom: '0',
      },
    },
  }),
  ...tablet({
    title: {
      fontSize: '4.4rem',
    },
    text: {
      fontSize: '1.8rem',
      maxWidth: '32rem',
    },
    gradient: {
      display: 'none',
    },
    overlay: {
      display: 'none',
    },
    figure: {
      '& > img': {
        transform: 'translateY(0)',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        minWidth: '100%',
      },
    },
    bubbleLeft: {
      position: 'absolute',
      zIndex: 3,
      top: '-32rem',
      left: '-3rem',
      width: '56rem',
      height: '93rem',
      pointerEvents: 'none',
    },
    bubbleRight: {
      position: 'absolute',
      width: '13rem',
      height: '31rem',
      top: '-8rem',
      right: '0rem',
      zIndex: 4,
      pointerEvents: 'none',
    },
  }),
  ...desktop({
    title: {
      fontSize: '4.6rem',
      letterSpacing: '0.00357143px',
    },
    text: {
      fontSize: '2rem',
      maxWidth: '52.5rem',
      '&:not(:first-child)': {
        marginTop: '3rem',
        marginBottom: '0rem',
      },
    },
    link: {
      fontSize: '1.5rem',
    },
    figure: {
      '& > img': {
        // objectPosition: 'center -18vw',
      },
    },
    bubbleLeft: {
      top: '-32rem',
      left: '-37rem',
      width: '95rem',
      height: '95rem',
    },
    bubbleRight: {
      display: 'block',
      top: '4rem',
      right: '-1rem',
      width: '33rem',
      height: '76rem',
      transform: 'scale(1, -1)',
    },
  }),
  ...biggestDesktop({
    figure: {
      '& > img': {
        objectPosition: 'center -16vw',
      },
    },
    bubbleLeft: {
      left: '-30rem',
    },
    bubbleRight: {
      top: '4rem',
    },
  }),
  '@media print': {
    toolsZone: {
      display: 'none',
    },
  },
}
