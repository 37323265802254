import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import Image from '../Image'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PracticeTabs = (props) => {
  const {
    classes: classesProp,
    className,
    id,
    tabs,
    lexicon,
    currentTab,
    currentContent,
    handleClickTab,
    callbackClickContent,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const handleClickContent = useCallback((e, index) => {
    if (e.target.closest('blockquote:not(:only-child)')) {
      if (index === currentContent) {
        callbackClickContent(-1)
      } else {
        callbackClickContent(index)
      }
    }
  }, [callbackClickContent, currentContent])

  const newCurrentTab = useMemo(() => {
    if (tabs && currentTab > tabs.length - 1) {
      return 0
    }
    return currentTab
  }, [currentTab, tabs])

  return tabs ? (
    <div
      className={cx(classes.container, className)}
      id={id}
    >
      <ul
        role="navigation"
        className={classes.tabs}
      >
        {tabs.map((tab, index) => (
          <li
            key={`practicetabs-tab-${index}`}
            className={classes.item}
          >
            <button
              type="button"
              className={cx(classes.button, newCurrentTab === index && 'is-active')}
              onClick={() => handleClickTab(index)}
            >
              {tab.imageTab && (
                <Image
                  {...tab.imageTab}
                  className={classes.imageTab}
                />
              )}
              {tab.imageTab && tab.imageTabActive && (
                <Image
                  {...tab.imageTabActive}
                  className={cx(classes.imageTab, 'is-active')}
                />
              )}
              {tab.imageTab ? <i>{tab.text}</i> : <span>{tab.text}</span>}
            </button>
          </li>
        ))}
      </ul>
      <div className={classes.contents}>
        {tabs[newCurrentTab]?.contents.map((content, index) => (
          <MarkdownStyled
            key={`practicetabs-content-${index}`}
            className={cx(classes.content, currentContent === index && 'is-active')}
            text={content}
            onClick={(e) => handleClickContent(e, index)}
            lexicon={lexicon}
            markHover={markHover}
          />
        ))}
      </div>
      <div className={classes.legend}>
        {tabs[newCurrentTab] && (
          <h3 className={classes.textLegend}>
            <Image
              {...tabs[newCurrentTab].imageTabBlack}
              className={classes.imageLegend}
            />
            {tabs[newCurrentTab].text}
          </h3>
        )}
      </div>
    </div>

  ) : null
}

PracticeTabs.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  id: PropTypes.string,
  currentTab: PropTypes.number,
  currentContent: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      imageTab: PropTypes.shape(Image.propTypes),
      imageTabActive: PropTypes.shape(Image.propTypes),
      imageTabBlack: PropTypes.shape(Image.propTypes),
      text: PropTypes.string,
      contents: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  lexicon: PropTypes.arrayOf(PropTypes.object),
  handleClickTab: PropTypes.func,
  callbackClickContent: PropTypes.func,
  markHover: PropTypes.func,
}

PracticeTabs.defaultProps = {
  className: null,
  classes: null,
  id: null,
  tabs: null,
  lexicon: null,
  currentTab: 0,
  currentContent: 0,
  handleClickTab: () => null,
  callbackClickContent: () => null,
  markHover: () => null,
}

export default withMemo()(PracticeTabs)

