import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useKeenSlider } from 'keen-slider/react'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Image from '../Image'
import Icon from '../Icon'
import icons from '../Icon/assets'


import styles from './styles'


const useStyles = createUseStyles(styles)

const NavPractice = (props) => {
  const {
    classes: classesProp,
    className,
    practices,
    numberColumns,
    textNext,
    textPrevious,
    isSmallerOnMobile,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  // states
  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentLastSlide, setCurrentLastSlide] = useState(0)

  // slider settings
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 1,
    mounted() {
      if (sliderRef.current) {
        sliderRef.current.style.visibility = 'visible'
      }
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
      setCurrentLastSlide(s.details().relativeSlide)
    },
    breakpoints: {
      '(min-width: 340px)': {
        slidesPerView: 2,
        slideChanged(s) {
          setCurrentSlide(s.details().relativeSlide)
          setCurrentLastSlide(s.details().relativeSlide + 1)
        },
      },
      '(min-width: 575px)': {
        slidesPerView: 3,
        slideChanged(s) {
          setCurrentSlide(s.details().relativeSlide)
          setCurrentLastSlide(s.details().relativeSlide + 2)
        },
      },
      '(min-width: 750px)': {
        slidesPerView: 4,
        slideChanged(s) {
          setCurrentSlide(s.details().relativeSlide)
          setCurrentLastSlide(s.details().relativeSlide + 3)
        },
      },
    },
  })

  // handlers
  const handlePrev = useCallback(() => {
    if (slider) {
      slider.prev()
    }
  }, [slider])

  const handleNext = useCallback(() => {
    if (slider) {
      slider.next()
    }
  }, [slider])

  const handleResize = useCallback(() => {
    if (slider) {
      slider.resize()
    }
  }, [slider])

  // effects
  // - slider resize on change fonts size
  // - slider resize on window resize
  useEffect(() => {
    const o = ((typeof window !== 'undefined')) ? new MutationObserver(() => {
      handleResize()
    }) : null

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      if (o !== null) {
        o.observe(document.querySelector('html'), { attributes: true, attributeFilter: ['style'] })
      }
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleResize)
          if (o !== null) {
            o.disconnect()
          }
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleResize, slider])

  // renderers
  const renderSlider = useMemo(() => {
    const practicesColumns = []

    for (let i = 0; i < numberColumns; i++) {
      const partialArray = practices.filter((item, index) => (((index - i) / numberColumns) % 1) === 0 && item)

      practicesColumns.push(partialArray)
    }

    return (
      <div
        ref={sliderRef}
        className={cx(classes.keenSlider, 'keen-slider')}
      >
        {practicesColumns.map((column, index) => (
          <div
            key={`navpractice-column-${index}`}
            className={cx(classes.column, 'keen-slider__slide')}
          >
            <ul className={classes.items}>
              {column.map((practice, idx) => (
                <li
                  key={`navpractice-column-${index}-${idx}`}
                  className={classes.item}
                >
                  <A
                    {...practice.linkProps}
                    className={classes.link}
                  >
                    <figure className={classes.figure}>
                      <Image
                        className={classes.image}
                        {...practice.image}
                      />
                    </figure>
                    <h2 className={classes.text}>
                      {practice.text}
                    </h2>
                  </A>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    )
  // eslint-disable-next-line max-len
  }, [classes.column, classes.figure, classes.image, classes.item, classes.items, classes.keenSlider, classes.link, classes.text, numberColumns, practices, sliderRef])

  return (
    <nav className={cx(classes.container, className, isSmallerOnMobile === true && 'is-smaller--mobile')}>
      <div className={classes.wrapper}>
        <div className={classes.slider}>
          {renderSlider}
          {slider && (
            <>
              <button
                type="button"
                className={cx(classes.previous, classes.nav, currentSlide === 0 && 'is-disabled')}
                onClick={handlePrev}
              >
                <Icon
                  icon={icons.ChevronSliderLeft}
                  className={classes.navIcon}
                />
                <i>{textPrevious}</i>
              </button>
              <button
                type="button"
                className={cx(classes.next, classes.nav, (currentLastSlide === numberColumns - 1) && 'is-disabled')}
                onClick={handleNext}
              >
                <Icon
                  icon={icons.ChevronSliderRight}
                  className={classes.navIcon}
                />
                <i>{textNext}</i>
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  )
}

NavPractice.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  practices: PropTypes.arrayOf(
    PropTypes.shape({
      linkProps: PropTypes.shape(A.propTypes),
      image: PropTypes.shape(Image.propTypes),
      text: PropTypes.string,
    })
  ).isRequired,
  numberColumns: PropTypes.number,
  textNext: PropTypes.string.isRequired,
  textPrevious: PropTypes.string.isRequired,
  isSmallerOnMobile: PropTypes.bool,
}

NavPractice.defaultProps = {
  className: null,
  classes: null,
  numberColumns: 4,
  isSmallerOnMobile: false,
}

export default withMemo()(NavPractice)
