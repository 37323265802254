import { accessibleHiddenText, colors, mobile, tablet } from '../../theme'


export default {
  container: {
    background: colors.greyF6,
    paddingTop: '5rem',
    paddingBottom: '4rem',
    '&:not(first-child)': {
      marginTop: '4rem',
    },
  },
  wrapper: {
    maxWidth: 'calc(109rem + 4rem)',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  title: {
    maxWidth: '22rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontWeight: 'bold',
    fontSize: '3.4rem',
    color: colors.blue,
    textTransform: 'uppercase',
    '& > span:first-child::before': {
      width: '4.5rem',
    },
  },
  links: {
    textAlign: 'center',
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  linkMore: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: colors.blue,
    textDecoration: 'underline',
  },
  // slider stuff
  slider: {
    position: 'relative',
    '&:not(:first-child)': {
      marginTop: '4rem',
    },
    '& > .keen-slider': {
      visibility: 'hidden',
      maxWidth: '80rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  nav: {
    position: 'absolute',
    top: '50%',
    zIndex: 5,
    display: 'none',
    color: colors.blue,
    padding: '1rem',
    transform: 'translateY(-40%)',
    cursor: 'pointer',
    '&.is-disabled': {
      opacity: '50%',
      pointerEvents: 'none',
    },
    '& i': {
      ...accessibleHiddenText,
    },
  },
  previous: {
    left: '-1rem',
  },
  next: {
    right: '-1rem',
  },
  navIcon: {
    width: '1.5rem',
    height: '3rem',
  },
  pagination: {
    textAlign: 'center',
    '&:not(:first-child)': {
      marginTop: '4rem',
    },
  },
  dot: {
    width: '0.8rem',
    height: '0.8rem',
    background: 'transparent',
    border: `1px solid ${colors.blue}`,
    borderRadius: '50%',
    transform: 'scale3d(1, 1, 1)',
    transition: 'all 200ms ease-out',
    '&:not(:first-child)': {
      marginLeft: '1rem',
    },
    '&.is-active': {
      transform: 'scale3d(1.5, 1.5, 1)',
      background: colors.blue,
    },
    '& i': {
      ...accessibleHiddenText,
    },
  },
  // question
  question: {},
  questionTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: '2rem',
    },
  },
  questionPeriod: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: '2rem',
    background: colors.purpleQuestions,
    color: colors.pureWhite,
    padding: '0.2rem 0.8rem',
    width: 'unset',
    '@media (max-width: 325px)': {
      width: 'min-content',
    },
  },
  questionIcon: {
    flex: '0 0 auto',
    width: '6.8rem',
  },
  questionMetas: {
    textAlign: 'center',
    fontWeight: 'bold',
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  questionDate: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1.6rem',
    lineHeight: '2',
    textTransform: 'uppercase',
    color: colors.turquoiseDark,
    '& > *': {
      margin: 0,
    },
  },
  questionAuthor: {
    position: 'relative',
    display: 'block',
    textAlign: 'center',
    fontSize: '1.5rem',
    lineHeight: '2',
    color: colors.blue,
    '& > *': {
      margin: 0,
    },
    '&:not(first-child)': {

    },
    '&:not(first-child)::before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '0',
      height: '1px',
      width: '2rem',
      background: colors.bluePale,
      transform: 'translateX(-50%)',
    },
  },
  questionText: {
    margin: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.8rem',
    '&::before': {
      content: '"«"',
    },
    '&::after': {
      content: '"»"',
    },
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  questionLinks: {
    textAlign: 'center',
    '&:not(:first-child)': {
      marginTop: '3.5rem',
    },
  },
  questionLink: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '4rem',
    paddingTop: '0.4rem',
    paddingBottom: '0.2rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    fontWeight: 'bold',
    fontSize: '1.3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.blueQuestions,
    background: colors.turquoiseQuestions,
  },
  ...mobile({
    title: {
      maxWidth: 'max-content',
    },
    linkMore: {
      fontSize: '1.6rem',
    },
    questionDate: {
      fontSize: '1.7rem',
    },
    questionAuthor: {
      fontSize: '1.7rem',
    },
    questionText: {
      fontSize: '2.4rem',
    },
    questionLink: {
      fontSize: '1.5rem',
    },
  }),
  ...tablet({
    container: {
      paddingTop: '7rem',
    },
    nav: {
      display: 'block',
    },
    questionDate: {
      display: 'inline',
    },
    questionAuthor: {
      display: 'inline',
      '&:not(first-child)': {
        paddingLeft: '1.5rem',
        marginLeft: '1.5rem',
      },
      '&:not(first-child)::before': {
        left: 0,
        top: '50%',
        height: '2rem',
        width: '1px',
        transform: 'translateY(-50%)',
      },
    },
  }),
}
